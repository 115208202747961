// src/components/Room.js

import React, { useState, useEffect, useRef } from 'react';
import ClientsList from './ClientsList';
function Room({ signalingServer, roomId }) {
  const [clientId, setClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const localStream = useRef(null);
  const peerConnections = useRef({});
  const [muteMicrophone, setMuteMicrophone] = useState(false);
  const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };

  useEffect(() => {
    if (!signalingServer) return;

    signalingServer.on('id', handleIdAssignment);
    signalingServer.on('updateUserList', handleUserListUpdate);
    signalingServer.on('newUserJoined', handleNewPeerJoining);
    signalingServer.on('offer', handleOffer);
    signalingServer.on('answer', handleAnswer);
    signalingServer.on('iceCandidate', handleIceCandidate);

    // Join the room only after obtaining the local stream
    getMediaStream().then(() => joinRoom());

    return () => {
      signalingServer.off('id', handleIdAssignment);
      signalingServer.off('updateUserList', handleUserListUpdate);
      signalingServer.off('newUserJoined', handleNewPeerJoining);
      signalingServer.off('offer', handleOffer);
      signalingServer.off('answer', handleAnswer);
      signalingServer.off('iceCandidate', handleIceCandidate);
    };
  }, [signalingServer, roomId]);

  const handleIdAssignment = (id) => {
    console.log(`Setting client ID: ${id}`);  
    setClientId(id); 
  };

  const handleUserListUpdate = (userList) => {
    setClients(userList);
    console.log('Updated user list:', userList);
    const updateAudio = new Audio('update.mp3');
    updateAudio.play().catch(error => {
    console.error('Error playing sound:', error);
  });
  };

  const handleNewPeerJoining = (newClientId) => {
    if (newClientId !== clientId && localStream.current) {
      handleNewPeer(newClientId);
    }
  };

  const joinRoom = () => {
    signalingServer.emit('joinRoom', { roomId, mediaType: 'audio' });
    console.log(`Joined room: ${roomId}`);
  };

  const getMediaStream = async () => {
    try {
      localStream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Local audio stream obtained');
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const handleNewPeer = (peerId) => {
    if (peerId !== clientId && !peerConnections.current[peerId]) {
      const peerConnection = createPeerConnection(peerId);
      peerConnections.current[peerId] = peerConnection;

      peerConnection.createOffer()
        .then(offer => peerConnection.setLocalDescription(offer))
        .then(() => {
          signalingServer.emit('offer', {
            offer: peerConnection.localDescription,
            roomId,
            to: peerId,
            from: clientId,
          });
        })
        .catch(error => console.error('Error creating or sending offer:', error));
    }
  };
  const toggleMicrophone = () => {
    if (localStream.current) {
      const audioTrack = localStream.current.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setMuteMicrophone(!audioTrack.enabled);
    }
  };
  const createPeerConnection = (peerId) => {
    const peerConnection = new RTCPeerConnection(configuration);

    if (localStream.current) {
      localStream.current.getTracks().forEach(track => peerConnection.addTrack(track, localStream.current));
    }

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        signalingServer.emit('iceCandidate', {
          candidate: event.candidate,
          roomId,
          to: peerId,
          from: clientId,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      const remoteStream = event.streams[0];
      if (remoteStream) {
        const audioElement = document.createElement('audio');
        audioElement.srcObject = remoteStream;
        audioElement.autoplay = true;
        audioElement.id = `audio-${peerId}`;  
        document.body.appendChild(audioElement);
      }
    };
    

    return peerConnection;
  };

  const handleOffer = (data) => {
    const { offer, from } = data;
    const peerConnection = createPeerConnection(from);
    peerConnections.current[from] = peerConnection;

    peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
      .then(() => peerConnection.createAnswer())
      .then(answer => peerConnection.setLocalDescription(answer))
      .then(() => {
        signalingServer.emit('answer', {
          answer: peerConnection.localDescription,
          roomId,
          to: from,
          from: clientId,
        });
      })
      .catch(error => console.error('Error handling offer:', error));
  };

  const handleAnswer = (data) => {
    const { answer, from } = data;
    const peerConnection = peerConnections.current[from];
    if (peerConnection) {
      peerConnection.setRemoteDescription(new RTCSessionDescription(answer)).catch(error => console.error('Error setting remote description from answer:', error));
    }
  };

  const handleIceCandidate = (data) => {
    const { candidate, from } = data;
    const peerConnection = peerConnections.current[from];
    if (peerConnection) {
      peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).catch(error => console.error('Error adding received ICE candidate:', error));
    }
  };

  return (
    <div>
      <button onClick={toggleMicrophone}>
        {muteMicrophone ? 'Unmute Microphone' : 'Mute Microphone'}
      </button>
      <ClientsList clients={clients} clientId={clientId} />
    </div>
  );
}

export default Room;
