// src/App.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Room from './components/Room';

const signalingServerUrl = 'https://main.mystshift.ru:5000';

function App() {
  const [signalingServer, setSignalingServer] = useState(null);
  const [roomId, setRoomId] = useState('');
  const [isJoined, setIsJoined] = useState(false);

  useEffect(() => {
    const socket = io(signalingServerUrl, { transports: ['websocket'] });
    setSignalingServer(socket);

    socket.on('connect', () => console.log('Connected to signaling server'));

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleJoinRoom = () => {
    if (roomId && signalingServer) {
      setIsJoined(true);
    }
  };

  return (
    <div className="App">
      <input 
        type="text" 
        id="roomIdInput" 
        placeholder="Enter room ID" 
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      />
      <button onClick={handleJoinRoom}>Join Room</button>
      {isJoined && signalingServer && <Room signalingServer={signalingServer} roomId={roomId} />}
    </div>
  );
}

export default App;
