// src/components/ClientsList.js
import React, { useState } from 'react';

function ClientsList({ clients, clientId }) {
  const [mutedClients, setMutedClients] = useState({});

  console.log(`Received clientId in ClientsList: ${clientId}`); // Check clientId passed down

  const toggleClientMute = (id) => {
    setMutedClients((prevMutedClients) => ({
      ...prevMutedClients,
      [id]: !prevMutedClients[id]
    }));

    const audioElement = document.getElementById(`audio-${id}`);
    if (audioElement) {
      audioElement.muted = !audioElement.muted;
    }
  };

  return (
    <ul>
      {clients.map((client) => (
        <li key={client.id}>
          Client ID: {client.id}
          {client.id !== clientId ? (
            <button onClick={() => toggleClientMute(client.id)}>
              {mutedClients[client.id] ? 'Unmute' : 'Mute'}
            </button>
          ) : (
            <span> (You) </span>  // Optional label for clarity
          )}
        </li>
      ))}
    </ul>
  );
}

export default ClientsList;
